import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// axios 接入
import axios from '@/plugins/axiosInstance.js'

// element ICON 注册
const app = createApp(App)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 全局组件注册
import useSubTitle from '@/components/useSubTitle.vue'
import useButton from '@/components/useButton.vue'
import useNavList from './components/useNavList.vue'
app.component('useSubTitle', useSubTitle)
app.component('useButton', useButton)
app.component('useNavList', useNavList)

app.use(ElementPlus, {
    locale: zhCn,
})

// 动画组件 animate
import animate from 'animate.css';
app.use(animate);

app.use(store).use(router).mount('#app');
app.config.globalProperties.$axios=axios;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
