<template>
    <div class="testTime">
        <div class="header">
            <img src="@/assets/home/testTime.png" alt="">
            <p>报考时间一览</p>
        </div>
        <div class="body">
            <div class="table">
                <el-table :data="props.testTableData" style="width: 100%" stripe border @cell-click="tableCellBtn">
                    <el-table-column v-for="(item, index) in props.testTableHeader" :prop="item.key" :label="item.label"
                        :key="index" :width="item.width" align="center">
                        <template #default="scope"
                            v-if="item.key == 'outline' || item.key == 'publish' || item.key == 'payTime' || item.key == 'qualified'">
                            <span class="tableSlot">
                                {{ scope.row[item.key] }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
            <img src="@/assets/home/kefu.png" @click="useCustomerService()" alt="">
        </div>
    </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js';
const props = defineProps(['testTableData', 'testTableHeader'])
const emits = defineEmits(['cellBtn'])

const tableCellBtn = (row, column) => {
    emits('cellBtn', column)
}
</script>

<style lang="scss" scoped>
.testTime {
    border: 1px solid #dcdcdc;
    margin: 20px 0 10px 0;
    height: 535px;

    .header {
        height: 47px;
        display: flex;
        border-bottom: 1px solid #dcdcdc;

        img {
            width: 34px;
            height: 34px;
            margin: 7px 13px;
        }

        p {
            line-height: 47px;
            font-size: 18px;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;

        .table {
            width: 900px;
            height: 350px;
            margin-top: 30px;
            margin-left: 10px;

            :deep(.cell) {
                cursor: default;
            }
            .tableSlot {
                cursor: pointer;
            }
            .tableSlot:hover {
                color: red;
            }
        }

        img {
            cursor: pointer;
        }
    }
}
</style>