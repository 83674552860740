<template>
    <div class="suspend-left">
        <p class="title">易岸教育</p>
        <ul class="navList">
            <li v-for="(item, index) in navList" :key="index" @click="useCustomerService">
                {{ item }}
            </li>
        </ul>
        <ul class="bottom">
            <li @click="useCustomerService"><span>快速一次上岸</span></li>
            <li @click="useCustomerService"><span>领取备考资料</span></li>
        </ul>
        <p class="top">TOP</p>
        <el-backtop :right="100" :bottom="100"></el-backtop>
    </div>
</template>

<script setup>
import { useCustomerService } from '@/api/common.js'
import { reactive } from "vue";

const navList = reactive([
    '公开课0元试听 >>',
    '重点考点解析 >>',
    '要点难点归纳 >>',
    '新旧大纲变化 >>',
    '在线模拟考试 >>',
    '学管增值服务 >>',
    '岗位招录查询 >>',
    '行业前景解读 >>',
    '制定备考方案 >>'
]);
</script>

<style lang="scss" scoped>
.suspend-left {
    width: 184px;
    height: 595px;
    position: fixed;
    top: 163px;
    left: 15px;
    background: url('@/assets/image/suspendLeft.png') no-repeat;
    z-index: 10;

    .title {
        width: 83px;
        height: 21px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        line-height: 50px;
        font-size: 14px;
    }

    .navList {
        width: 140px;
        margin-left: 24px;
        margin-top: 63px;

        li {
            width: 140px;
            height: 35px;
            line-height: 35px;
            margin-bottom: 3px;
            border-bottom: 1px solid #ccc;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 4px 0px rgba(39, 39, 39, 1);
            text-align: center;
            font-size: 14px;
            color: #4d4d4d;
            cursor: pointer;
            transition: all 0.5s;
        }

        li:nth-child(even) {
            color: #EB6100;
        }

        li:hover {
            transform: translateY(-3px);
        }
    }

    .bottom {
        width: 130px;
        margin-left: 30px;
        margin-top: 20px;

        li {
            width: 130px;
            height: 30px;
            line-height: 30px;
            background: #ef506e;
            margin-top: 4px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            color: #fff;
            cursor: pointer;

            span {
                -webkit-animation: swtCs 1.5s linear infinite;
                animation: swtCs 1.5s linear infinite;
                cursor: pointer;
            }
        }
    }

    .top {
        width: 140px;
        margin-left: 25px;
        height: 60px;
        margin-top: 8px;
        padding-top: 5px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        letter-spacing: 5px;
        cursor: pointer;
    }
}
</style>