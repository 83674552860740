<template>
  <div class="passage">
    <div class="top">
      <p class="title">高端通关</p>
      <p class="subTitle">四大课程体系助你一次通过</p>
      <img src="@/assets/home/passage.jpg" alt="">
    </div>
    <div class="bottom">
      <p class="subLi">
        <span>合作机构</span>
      </p>
      <ul>
        <li v-for="(item, index) in partnerList" :key="index">
          <img class="zoom_img" :src="require(`@/assets/home/${item}`)" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

const partnerList = reactive([
  'baidu.jpg',
  '360.jpg',
  'toutiao.jpg',
  'shenma.jpg',
  'souhu.jpg',
  'xinlang.jpg',
]);
</script>

<style lang="scss" scoped>
.passage {
  height: 534px;

  .top {
    p {
      text-align: center;
      color: #666;
    }

    .title {
      font-size: 30px;
      padding-top: 50px;
      letter-spacing: 10px;
    }

    .subTitle {
      font-size: 18px;
      padding: 10px 0 30px 0;
    }

    img {
      margin-left: 57px;
    }
  }

  .bottom {
    margin-top: 30px;

    .subLi {
      height: 48px;
      border-bottom: 1px solid #ccc;

      span {
        color: #f13a3a;
        display: block;
        line-height: 47px;
        width: 80px;
        text-align: center;
        padding: 0 10px;
        border-bottom: 3px solid #f13a3a;
      }
    }

    ul {
      display: flex;
      margin: 20px 20px;

      li {
        width: 180px;
        height: 60px;
        border: 1px solid #ccc;
        overflow: hidden;
        margin-right: 20px;
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }

}
</style>