<template>
  <div class="header">
    <div class="topAdvAreaWrap" v-show="isTop">
      <div class="sideForms" @click="useCustomerService"></div>
      <div class="top_adv_close" @click="closeTop">
        <img src="@/assets/home/关闭.png" alt="">
      </div>
    </div>
    <div class="topNav">
      <div class="overflowCenter">
        <img src="../assets/logo-black.png" alt="" class="logo" />
        <span> | 专注上岸的网校 </span>
        <ul class="navList">
          <li v-for="(item, index) in navList" :key="index" @click="navBtn(item)"
            :class="[item.name == route.name ? 'active' : '']">
            {{ item.title }}
          </li>
        </ul>
        <p>服务热线：<span>400-188-1870</span></p>
        <img src="@/assets/image/phone.gif" class="phone" alt="">
      </div>
    </div>
    <!-- <img src="//30594067.s21i.faimallusr.com/2/ABUIABACGAAg-IjWnwYozMelnAcwgA842AQ.jpg" alt=""
      class="homeBanner" @click="useCustomerService" /> -->
    <!-- <img src="@/assets/home/bannerOne.png" class="homeBanner" @click="useCustomerService" alt=""> -->
  </div>
</template>

<script setup>
import { navList } from "../api/navList";
import { useCustomerService } from '@/api/common.js';
import { ref } from "vue";
import { useRoute } from "vue-router";
const isTop = ref(true)

const closeTop = () => {
  isTop.value = false;
}

// const router = useRouter();
const route = useRoute();
console.log(route, 'route')
const navBtn = (item) => {
  // router.push(item.path)
  window.open(item.path, '_blank')
};
</script>

<style scoped lang="scss">
.active {
  color: red;
  font-weight: bold;
  font-size: 20px;
}

.header {
  width: 100%;
  .topAdvAreaWrap {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: url("../assets/topBgc.png") no-repeat 0 0;

    .sideForms {
      width: 1200px;
      height: 85px;
    }

    .top_adv_close {
      z-index: 8;
      position: absolute;
      top: 24px;
      right: 65px;
      width: 35px;
      height: 35px;
      cursor: pointer;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .topNav {
    width: 100%;
    height: 80px;
    // background: black;
    background: #fff;
    // background: #ed2020;
  }

  .overflowCenter {
    width: 1500px;
    margin-left: 307px;
    display: flex;

    .logo {
      margin-top: 15px;
      width: 200px;
      height: 50px;
    }

    span {
      // color: rgb(255, 235, 60);
      color: red;
      line-height: 85px;
      padding-left: -20px;
      cursor: default;
    }

    ul {
      display: flex;
      margin-left: 30px;
      // width: 80%;

      li {
        // margin: 0 10px;
        margin-right: 35px;
        line-height: 85px;
        // color: white;
        cursor: pointer;
      }
    }

    P {
      // color: white;
      font-size: 20px;
      margin-left: 80px;
    }
    .phone {
      width: 80px;
      height: 50px;
      margin-top: 20px;
    }
  }
}
</style>
