<template>
    <div class="careerBody">
        <div class="careerLeftBody">
            <div class="topBody">
                <div class="leftCareer">
                    <el-carousel trigger="click" height="215px" width="410px">
                        <el-carousel-item>
                            <img @click="useCustomerService" src="@/assets/home/swiper1.jpg" alt="" />
                        </el-carousel-item>
                        <el-carousel-item>
                            <img @click="useCustomerService" src="@/assets/home/swiper2.jpg" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="rightCareer">
                    <use-nav-list :navObj="enterprise"></use-nav-list>
                    <use-nav-list :navObj="enrollList" class="noneBottom"></use-nav-list>
                </div>
            </div>
            <div class="bottomBody">
                <ul>
                    <li @click="useCustomerService()">
                        <img src="@/assets/home/bottom1.png" alt="">
                        <p>考试日历</p>
                    </li>
                    <li @click="useCustomerService()">
                        <img src="@/assets/home/bottom2.png" alt="">
                        <p>章节练习</p>
                    </li>
                    <li @click="useCustomerService()">
                        <img src="@/assets/home/bottom3.png" alt="">
                        <p>模拟考试</p>
                    </li>
                    <li @click="useCustomerService()">
                        <img src="@/assets/home/bottom4.png" alt="">
                        <p>历年真题</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="careerRightBody">
            <div class="applicationGuide">
                <div class="topGuide">
                    <span>报考指南</span>
                    <ul>
                        <li :class="[isRed == 1 ? 'redEmit' : '']" @mouseover="isMethodRed(1)">事业编</li>
                        <li :class="[isRed == 2 ? 'redEmit' : '']" @mouseover="isMethodRed(2)">公务员</li>
                    </ul>
                </div>
                <ul class="appContent">
                    <li v-for="(item, index) in appList" :key="index" @click="useCustomerService"> {{ item }}</li>
                </ul>
            </div>
            <div style="clear:both"></div>
            <div class="todayHots">
                <p class="title">
                    <span>今日热点</span>
                </p>
                <ul class="hotList">
                    <li v-for="(item, index) in hotList" :key="index" @click="useContentDetail(item)">
                        <label>HOT</label>
                        <span>{{ item.title }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useCustomerService, useContentDetail } from '@/api/common.js'
import { enterprise, enrollList } from "@/api/navList";
// 报考指南修改颜色
let isRed = ref(1);
function isMethodRed(item) {
    isRed.value = item
}

// 报考指南list
const appList = reactive(['考试介绍', '考试时间', '考试科目', '报考时间', '报名条件', '证书注册', '成绩查询', '合格标准', '成绩管理'])

// 今日热点 hotList

const hotList = reactive([
    {
        title: '公务员怎么选岗位更容易进面？',
        params: 'hotOne',
    },
    {
        title: ' 军队文职岗位报考注意事项！',
        params: 'hotTwo',
    },
    {
        title: '教师编制笔试重点归纳',
        params: 'hotThree',
    },
    {
        title: '备考必看！公基必背考点50条',
        params: 'hotFour',
    },
    {
        title: '2023山东省公务员考试行测备考策略',
        params: 'hotFive',
    },
]);
</script>

<style lang="scss">
.careerBody {
    width: 1200px;
    margin-left: 10px;
    margin-top: 10px;
    display: flex;

    .careerLeftBody {
        .topBody {
            display: flex;
            margin-top: 5px;

            .leftCareer {
                width: 410px;
                margin-right: 28px;
                img {
                    width: 410px;
                    height: 215px;
                }
            }
        }

        .bottomBody {
            width: 810px;

            ul {
                border-top: 1px dashed #666;
                margin-top: 7px;
                display: flex;

                li {
                    margin: 10px 13px 0 0;
                    
                    img {
                        width: 190px;
                        height: 125px;
                        cursor: pointer;
                    }

                    p {
                        text-align: center;
                        color: #666;
                        font-size: 14px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

    .careerRightBody {
        width: 355px;
        height: 400px;
        margin-left: 20px;

        .applicationGuide {
            color: #E4393C;

            .topGuide {
                height: 28px;
                border-bottom: 2px solid #E4393C;
                position: relative;

                span {
                    font-size: 18px;
                }

                ul {
                    position: absolute;
                    right: 2px;
                    bottom: 3px;
                    display: flex;

                    li {
                        cursor: pointer;
                        padding: 3px 10px;
                    }

                    .redEmit {
                        color: white;
                        background: #E4393C;
                    }
                }
            }

            .appContent {
                margin: 5px 0 0 3px;

                li {
                    float: left;
                    width: 102px;
                    height: 30px;
                    border: 1px solid #E4393C;
                    text-align: center;
                    line-height: 30px;
                    margin: 10px 20px 4px 0px;
                    cursor: pointer;
                }

                li:nth-child(3),
                li:nth-child(6),
                li:nth-child(9) {
                    margin: 10px 0px 4px 0px !important;
                }

                li:hover {
                    color: white;
                    background: #E4393C;
                }
            }
        }

        .todayHots {
            margin-top: 10px;

            .title {
                font-size: 18px;
                line-height: 30px;
                padding-bottom: 1px;
                border-bottom: 1px solid #999;

                span {
                    color: #F4393C;
                    padding: 0px 16px 3px 14px;
                    border-bottom: 3px solid #E4393C;
                }
            }

            .hotList {
                li {
                    line-height: 30px;
                    height: 30px;
                    margin: 8px 0;
                    font-size: 14px;
                    color: #666;
                    position: relative;

                    img {
                        margin-left: 10px;
                        width: 25px;
                        height: 15px;
                        margin-top: 3px;
                    }

                    label {
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: 10px;
                        font-size: 12px;
                        line-height: 18px;
                        color: white;
                        text-align: center;
                        width: 30px;
                        height: 18px;
                        background: #eb474e;

                    }

                    span {
                        position: absolute;
                        cursor: pointer;
                        top: -2px;
                        left: 45px;
                    }
                }
            }
        }
    }
}
</style>